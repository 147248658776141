// src/components/HTMLWidget.js
import React, { useState, useEffect, useContext } from 'react';
import { fetchFromAPI } from './api';
import AuthContext from '../context/AuthContext';

const HTMLWidget = ({ file, className }) => {
  const [content, setContent] = useState('');
  const [error, setError] = useState(null);
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    if (!file) {
      setError('No file specified for the HTMLWidget.');
      return;
    }

    // Fetch the HTML content dynamically
    console.log(`Fetching: public/html-files/content?file=${file}`);
    fetchFromAPI(`public/html-files/content?file=${file}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to load content: ${response.statusText}`);
        }
        return response.text();
      })
      .then((html) => setContent(html))
      .catch((err) => {
        console.error('Error fetching HTML content:', err);
        setError('Failed to load HTML content.');
      });
  }, [file, accessToken]);

  if (error) {
    return <div className="html-widget-error">{error}</div>;
  }

  return (
    <div
      className={`html-widget ${className}`}
      dangerouslySetInnerHTML={{ __html: content }} // Render raw HTML
    />
  );
};

export default HTMLWidget;
