export const mockDashboardData = {
  totalSales: 1200, // Total number of books sold
  totalBooks: 15, // Total number of books published by the author
  totalEarnings: 4500.75, // Total earnings from sales
  salesOverMonths: [
    { month: 'January', sales: 100 },
    { month: 'February', sales: 120 },
    { month: 'March', sales: 110 },
    { month: 'April', sales: 130 },
    { month: 'May', sales: 90 },
    { month: 'June', sales: 100 },
    { month: 'July', sales: 140 },
    { month: 'August', sales: 150 },
    { month: 'September', sales: 170 },
    { month: 'October', sales: 160 },
    { month: 'November', sales: 180 },
    { month: 'December', sales: 190 },
  ], // Monthly sales data for the chart
  booksSoldThisMonth: [
    {
      id: '1',
      title: 'Mystery Novel',
      unitsSold: 150,
      revenue: 750.0,
    },
    {
      id: '2',
      title: 'Romance Tale',
      unitsSold: 100,
      revenue: 500.0,
    },
    {
      id: '3',
      title: 'Sci-Fi Adventure',
      unitsSold: 50,
      revenue: 250.0,
    },
  ], // Books sold this month, with units and revenue
};
