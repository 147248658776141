import React, { useState, useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Typography,
  Spin,
  Card,
  Tag,
  Button,
  Input,
  Pagination,
  Drawer,
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { fetchFromAPI } from './api';
import BookTile from './BookTile';
import { useLocation, useNavigate } from 'react-router-dom';
import './BrowsingPage.css';

const { Title } = Typography;

const BrowsingPage = () => {
  const [books, setBooks] = useState([]);
  const [genres, setGenres] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [showMobileFilters, setShowMobileFilters] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Fetch books based on the current filters and pagination.
   */
  const fetchBooks = useCallback(() => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      genre: selectedGenre
        ? genres.find((g) => g.id === selectedGenre)?.name
        : '',
      tags: selectedTags
        .map((tagId) => tags.find((tag) => tag.id === tagId)?.name)
        .join(','),
      page: currentPage,
      limit: pageSize,
    }).toString();

    fetchFromAPI(`search?${queryParams}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.hits)) {
          setBooks(data.hits);
          setTotalResults(data.totalHits || 0);
        } else {
          setBooks([]);
          setTotalResults(0);
        }
      })
      .catch(() => {
        setBooks([]);
        setTotalResults(0);
      })
      .finally(() => setLoading(false));
  }, [selectedGenre, selectedTags, currentPage, pageSize, genres, tags]);

  /**
   * Fetch genres and tags, and sync filters from the URL.
   */
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const [genreResponse, tagResponse] = await Promise.all([
          fetchFromAPI('public/genres'),
          fetchFromAPI('public/tags'),
        ]);

        const genresData = await genreResponse.json();
        const tagsData = await tagResponse.json();

        setGenres(genresData || []);
        setTags(tagsData || []);

        const queryParams = new URLSearchParams(location.search);
        const genreQuery = queryParams.get('genre');
        const tagsQuery = queryParams.getAll('tags');

        if (genreQuery) {
          const selectedGenreObj = genresData.find(
            (genre) => genre.name.toLowerCase() === genreQuery.toLowerCase(),
          );
          setSelectedGenre(selectedGenreObj?.id || '');
        }

        if (tagsQuery.length > 0) {
          const selectedTagIds = tagsQuery.map(
            (tagName) =>
              tagsData.find(
                (tag) => tag.name.toLowerCase() === tagName.toLowerCase(),
              )?.id,
          );
          setSelectedTags(selectedTagIds.filter(Boolean));
        }
      } catch (error) {
        console.error('Error fetching genres or tags:', error);
      }
    };

    fetchFilters();
  }, [location.search]);

  /**
   * Fetch books whenever relevant dependencies change.
   */
  useEffect(() => {
    if (!loading) fetchBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBooks]);

  const updateSearchParams = (genre, tags) => {
    const query = new URLSearchParams({
      genre: genre ? genres.find((g) => g.id === genre)?.name : '',
      tags: tags.map((id) => tags.find((tag) => tag.id === id)?.name).join(','),
    }).toString();

    navigate(`?${query}`, { replace: true });
  };

  const handleGenreChange = (genreId) => {
    const newGenre = selectedGenre === genreId ? '' : genreId;
    setSelectedGenre(newGenre);

    if (window.innerWidth > 768) {
      updateSearchParams(newGenre, selectedTags);
    }
  };

  const toggleTag = (tagId) => {
    const updatedTags = selectedTags.includes(tagId)
      ? selectedTags.filter((id) => id !== tagId)
      : [...selectedTags, tagId];
    setSelectedTags(updatedTags);

    if (window.innerWidth > 768) {
      updateSearchParams(selectedGenre, updatedTags);
    }
  };

  const handleApplyFilters = () => {
    updateSearchParams(selectedGenre, selectedTags);
    setShowMobileFilters(false);
  };

  const handlePageChange = (page, newPageSize) => {
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setCurrentPage(1); // Reset to first page on page size change
    } else {
      setCurrentPage(page);
    }
  };

  return (
    <div className="browsing-page">
      <Row gutter={[16, 16]} className="content-container">
        <Button
          className="mobile-filter-button"
          type="primary"
          icon={<MenuOutlined />}
          onClick={() => setShowMobileFilters(true)}
        >
          Filters
        </Button>

        <Drawer
          title="Filters"
          placement="top"
          closable
          onClose={() => setShowMobileFilters(false)}
          height="90vh"
          open={showMobileFilters}
        >
          <Card className="filters-card">
            <Title level={5}>Genres</Title>
            {genres.map((genre) => (
              <Tag
                key={genre.id}
                className={`ant-tag ${selectedGenre === genre.id ? 'ant-tag-selected' : ''}`}
                onClick={() => handleGenreChange(genre.id)}
              >
                {genre.name}
              </Tag>
            ))}
          </Card>

          <Card className="filters-card">
            <Title level={5}>Tags</Title>
            {tags.map((tag) => (
              <Tag
                key={tag.id}
                className={`ant-tag ${selectedTags.includes(tag.id) ? 'ant-tag-selected' : ''}`}
                onClick={() => toggleTag(tag.id)}
              >
                {tag.name}
              </Tag>
            ))}
          </Card>

          <Button
            type="primary"
            className="filters-apply-button"
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
        </Drawer>

        <Col xs={24} sm={6} className="filters-panel">
          <Card className="filters-card">
            <Title level={5}>Genres</Title>
            {genres.map((genre) => (
              <Tag
                key={genre.id}
                className={`ant-tag ${selectedGenre === genre.id ? 'ant-tag-selected' : ''}`}
                onClick={() => handleGenreChange(genre.id)}
              >
                {genre.name}
              </Tag>
            ))}
          </Card>

          <Card className="filters-card">
            <Title level={5}>Tags</Title>
            {tags.map((tag) => (
              <Tag
                key={tag.id}
                className={`ant-tag ${selectedTags.includes(tag.id) ? 'ant-tag-selected' : ''}`}
                onClick={() => toggleTag(tag.id)}
              >
                {tag.name}
              </Tag>
            ))}
          </Card>
        </Col>

        <Col xs={24} sm={18} className="books-grid">
          <div className="search-bar">
            <Input
              placeholder="Search books..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button type="primary" onClick={fetchBooks}>
              Search
            </Button>
          </div>

          {loading ? (
            <Spin size="large" />
          ) : books.length > 0 ? (
            <>
              <Row gutter={[16, 16]}>
                {books.map((book) => (
                  <Col key={book.id} xs={24} sm={12} md={8} lg={6}>
                    <BookTile
                      book={book}
                      onClick={() => navigate(`/book/${book.id}`)}
                    />
                  </Col>
                ))}
              </Row>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalResults}
                onChange={handlePageChange}
                className="pagination"
              />
            </>
          ) : (
            <p>No books found</p>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BrowsingPage;
