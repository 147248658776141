import React from 'react';
import { Layout, Card, List, Button, Typography, Progress } from 'antd';
import 'antd/dist/reset.css';

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;

const mockData = {
  name: 'Epic Reads Club',
  description: 'A place to read and discuss epic books.',
  currentBook: {
    title: 'The Fellowship of the Ring',
    author: 'J.R.R. Tolkien',
    cover: '/covers/generic.jpg',
    progress: 45,
  },
  discussions: [
    { id: 1, title: 'Favorite Quotes', comments: 12 },
    { id: 2, title: 'Character Deep Dive: Frodo', comments: 8 },
    { id: 3, title: 'Themes in Chapter 3', comments: 5 },
  ],
  bookHistory: [
    { id: 1, title: '1984', author: 'George Orwell' },
    { id: 2, title: 'Pride and Prejudice', author: 'Jane Austen' },
    { id: 3, title: 'Dune', author: 'Frank Herbert' },
  ],
};

const BookClubLandingPage = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ background: '#001529', padding: '0 20px' }}>
        <Title style={{ color: '#fff', margin: 0 }}>{mockData.name}</Title>
      </Header>
      <Content style={{ padding: '20px' }}>
        <Card style={{ marginBottom: '20px' }}>
          <Title level={4}>{mockData.description}</Title>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={mockData.currentBook.cover}
              alt="Current Book Cover"
              style={{ width: '100px', height: '150px', marginRight: '20px' }}
            />
            <div>
              <Title level={5}>{mockData.currentBook.title}</Title>
              <Paragraph>By {mockData.currentBook.author}</Paragraph>
              <Progress percent={mockData.currentBook.progress} />
            </div>
          </div>
        </Card>

        <Card style={{ marginBottom: '20px' }}>
          <Title level={4}>Discussion Board</Title>
          <List
            dataSource={mockData.discussions}
            renderItem={(item) => (
              <List.Item>
                <div>
                  <Title level={5}>{item.title}</Title>
                  <Paragraph>{item.comments} comments</Paragraph>
                </div>
              </List.Item>
            )}
          />
          <Button type="primary">View All Discussions</Button>
        </Card>

        <Card>
          <Title level={4}>Book History</Title>
          <List
            dataSource={mockData.bookHistory}
            renderItem={(item) => (
              <List.Item>
                <div>
                  <Title level={5}>{item.title}</Title>
                  <Paragraph>By {item.author}</Paragraph>
                </div>
              </List.Item>
            )}
          />
        </Card>
      </Content>
    </Layout>
  );
};

export default BookClubLandingPage;
