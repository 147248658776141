import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { fetchFromAPI } from './api'; // Utility for API calls
import './HTMLEditor.css'; // Import the external CSS file

const HTMLEditor = () => {
  const [files, setFiles] = useState([]); // List of HTML files
  const [selectedFile, setSelectedFile] = useState(null); // Currently selected file
  const [content, setContent] = useState(''); // File content

  // Fetch the list of HTML files
  useEffect(() => {
    fetchFromAPI('public/html-files')
      .then((response) => response.json())
      .then((data) => setFiles(data))
      .catch((error) => console.error('Error fetching file list:', error));
  }, []);

  // Load file content when a file is selected
  const loadFileContent = (fileName) => {
    fetchFromAPI(
      `public/html-files/content?file=${encodeURIComponent(fileName)}`,
    )
      .then((response) => response.text())
      .then((data) => {
        setSelectedFile(fileName);
        setContent(data);
      })
      .catch((error) => console.error('Error loading file content:', error));
  };

  // Save the edited content
  const saveFileContent = () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }

    fetchFromAPI(
      `protected/html-files/content?file=${encodeURIComponent(selectedFile)}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'text/html' },
        body: content,
      },
    )
      .then((response) => {
        if (response.ok) {
          alert('Content saved successfully!');
        } else {
          alert('Failed to save content.');
        }
      })
      .catch((error) => console.error('Error saving content:', error));
  };

  return (
    <div className="html-editor-page">
      {/* Scrollable File List */}
      <div className="file-list">
        <h2>HTML Files</h2>
        <ul>
          {files.map((file) => (
            <li
              key={file}
              onClick={() => loadFileContent(file)}
              className={file === selectedFile ? 'selected-file' : 'file-item'}
            >
              {file}
            </li>
          ))}
        </ul>
      </div>

      {/* Editor Section */}
      <div className="editor-container">
        <h2>
          {selectedFile ? `Editing: ${selectedFile}` : 'Select a file to edit'}
        </h2>
        {selectedFile && (
          <>
            <Editor
              apiKey="8sr7afjunza36deq21h78bmfp01uztp4ldilezm5jci6bg7w"
              value={content}
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  // eslint-disable-next-line no-multi-str
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help',
              }}
              onEditorChange={(newContent) => setContent(newContent)}
            />
            <button onClick={saveFileContent} className="save-button">
              Save
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default HTMLEditor;
