import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Spin, message } from 'antd';
import { fetchFromAPI } from './api';
import './CollectionsPage.css';

const { Title } = Typography;

const CollectionsPage = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await fetchFromAPI('public/collections');
        const data = await response.json();
        setCollections(data);
      } catch (error) {
        console.error('Error fetching collections:', error);
        message.error('Failed to fetch collections.');
      } finally {
        setLoading(false);
      }
    };

    fetchCollections();
  }, []);

  return (
    <div className="collections-page">
      <Title level={2} className="collections-title">
        Collections
      </Title>
      {loading ? (
        <Spin size="large" className="collections-spinner" />
      ) : (
        <Row gutter={[16, 16]}>
          {collections.map((collection) => (
            <Col xs={24} sm={12} md={8} lg={6} key={collection.id}>
              <Card
                title={collection.name}
                hoverable
                className="collection-card"
                onClick={() =>
                  console.log(`Navigate to collection: ${collection.id}`)
                }
              >
                <p>{collection.description || 'No description available.'}</p>
                <p>Price: {collection.price || 1} Credits</p>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default CollectionsPage;
