import React from 'react';
import { Carousel } from 'antd';
import './BookSlider.css';
import BookTile from './BookTile';

const BookSlider = ({ books, onBookClick }) => {
  const arrowStyle = {
    width: '40px',
    height: '40px',
    background: 'var(--accent-color)',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  const PrevArrow = ({ currentSlide, onClick }) => (
    <div
      style={{
        ...arrowStyle,
        left: '-40px',
        opacity: currentSlide === 0 ? 0.5 : 1,
      }}
      onClick={onClick}
    >
      {'<'}
    </div>
  );

  const NextArrow = ({ currentSlide, onClick }) => (
    <div
      style={{
        ...arrowStyle,
        right: '-40px',
      }}
      onClick={onClick}
    >
      {'>'}
    </div>
  );

  return (
    <div className="book-slider-container">
      <Carousel
        arrows
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        responsive={[
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
        dots
        infinite
        slidesToShow={6}
        slidesToScroll={1}
      >
        {books.map((book) => (
          <div key={book.id} className="carousel-item">
            <BookTile
              book={book}
              onClick={() => onBookClick(book.id)}
              isPurchased={book.isPurchased}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default BookSlider;
