// Updated AuthContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { fetchFromAPI } from '../components/api';

const AuthContext = createContext();
let authContextRef = null; // Global reference

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [credits, setCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const logout = useCallback(() => {
    setUser(null);
    setAccessToken(null);
    setIsAuthor(false);
    setIsAdmin(false);
    setCredits(0);
    localStorage.removeItem('userLibrary');
  }, []);

  const fetchAndCacheLibrary = useCallback(
    async (username) => {
      try {
        const response = await fetchFromAPI(`protected/library/${username}`);

        if (!response.ok) throw new Error('Failed to fetch library');

        let library = await response.json();

        // Include the downloaded status
        const downloadedBooks = JSON.parse(
          localStorage.getItem('downloadedBooks') || '[]',
        );

        library = library.map((book) => ({
          ...book,
          isDownloaded: downloadedBooks.includes(book.id),
        }));

        console.log('Auth Context retrieved library:', library);
        localStorage.setItem('userLibrary', JSON.stringify(library));

        return library;
      } catch (error) {
        console.error('Error fetching and caching library:', error);
        return JSON.parse(localStorage.getItem('userLibrary') || '[]');
      }
    },
    [],
  );

  const refreshAccessToken = useCallback(async () => {
    if (isRefreshing) return;
    setIsRefreshing(true);
    try {
      const response = await fetchFromAPI('protected/refresh-token', {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.accessToken);
        return data.accessToken;
      } else {
        logout();
        return null;
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
      return null;
    } finally {
      setIsRefreshing(false);
    }
  }, [logout, isRefreshing]);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        setIsLoading(true);

        const response = await fetchFromAPI('protected/refresh-token', {
          method: 'POST',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setAccessToken(data.accessToken);
          setUser(data.user);
          setIsAuthor(data.isAuthor);
          setIsAdmin(data.is_admin);
          setCredits(data.credits);
          await fetchAndCacheLibrary(data.user.username);
        } else {
          console.warn('Refresh token invalid or expired. Staying logged out.');
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!accessToken) {
      initializeAuth();
    }
  }, [accessToken, fetchAndCacheLibrary]);

  const login = useCallback(async (user, isAuthor, isAdmin, credits, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setUser(user);
        setAccessToken(token);
        setIsAuthor(isAuthor);
        setIsAdmin(isAdmin);
        setCredits(credits);
        setIsLoading(false);

        resolve(); // Indicate success
      } catch (error) {
        console.error('Error during login:', error);
        reject(error); // Indicate failure
      }
    });
  }, []);

  useEffect(() => {
    authContextRef = {
      user,
      accessToken,
      isAuthor,
      isAdmin,
      credits,
      logout,
      refreshAccessToken,
      setCredits,
    };
  }, [
    user,
    accessToken,
    isAuthor,
    isAdmin,
    credits,
    logout,
    refreshAccessToken,
    setCredits,
  ]);

  return (
    <AuthContext.Provider
      value={{
        user,
        accessToken,
        isAuthor,
        isAdmin,
        credits,
        isLoading,
        login,
        logout,
        refreshAccessToken,
        fetchAndCacheLibrary,
        setCredits,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Helper to get auth context outside of React components
export const getAuthContext = () => {
  if (!authContextRef && !authContextRef.accessToken) {
    throw new Error(
      'AuthContext is not initialized. Wrap your app in <AuthProvider>.',
    );
  }

  return authContextRef;
};

export default AuthContext;
