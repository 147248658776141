import React, { useContext, useState, useEffect } from 'react';
import {
  Typography,
  Collapse,
  Button,
  Modal,
  Checkbox,
  message,
  Spin,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import AuthorProfile from './AuthorProfile';
import UploadBook from './UploadBook';
import PayoutDetails from './PayoutDetails';
import AuthorBooks from './AuthorBooks';
import AuthContext from '../context/AuthContext';
import { fetchFromAPI } from './api';
import './MyAccount.css';

const { Title } = Typography;
const { Panel } = Collapse;

const MyAccount = () => {
  const { isAuthor, accessToken, user } = useContext(AuthContext);
  const [optInModalOpen, setOptInModalOpen] = useState(false);
  const [genres, setGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [loadingPreferences, setLoadingPreferences] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGenresAndPreferences = async () => {
      try {
        // Fetch genres
        const genresResponse = await fetchFromAPI('public/genres');
        if (!genresResponse.ok) {
          throw new Error(
            `Failed to fetch genres: ${genresResponse.statusText}`,
          );
        }
        const genresData = await genresResponse.json();
        setGenres(genresData);

        // Fetch preferences
        const preferencesResponse = await fetchFromAPI(
          `protected/profile/preferences/${user.id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        if (!preferencesResponse.ok) {
          throw new Error(
            `Failed to fetch preferences: ${preferencesResponse.statusText}`,
          );
        }
        const preferencesData = await preferencesResponse.json();
        setSelectedGenres(preferencesData.map((pref) => pref.id));
      } catch (error) {
        console.error('Error fetching genres or preferences:', error);
        message.error('Failed to load preferences.');
      } finally {
        setLoadingPreferences(false);
      }
    };

    fetchGenresAndPreferences();
  }, [user.id, accessToken]);

  const handlePreferencesSave = async () => {
    try {
      const response = await fetchFromAPI(
        `protected/profile/preferences/${user.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ genreIds: selectedGenres }),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to save preferences: ${response.statusText}`);
      }

      message.success('Preferences saved successfully!');
    } catch (error) {
      console.error('Error saving preferences:', error);
      message.error('Failed to save preferences.');
    }
  };

  const handleDashboardNavigation = () => {
    navigate('/author-dashboard');
  };

  const handleOptIn = async () => {
    try {
      // Simulate opt-in API call
      message.success(
        'You are now an author! Reload the page to access author features.',
      );
      setOptInModalOpen(false);
    } catch (error) {
      console.error('Error opting in as author:', error);
      message.error('Unable to opt-in as an author. Please try again.');
    }
  };

  return (
    <div className="my-account">
      <Title level={2} className="account-title">
        My Account
      </Title>
      {isAuthor && (
        <div className="dashboard-button">
          <Button type="primary" onClick={handleDashboardNavigation}>
            Go to Author Dashboard
          </Button>
        </div>
      )}
      <Collapse defaultActiveKey={['preferences']} accordion>
        <Panel header="Preferences" key="preferences">
          {loadingPreferences ? (
            <Spin size="large" />
          ) : (
            <div>
              <Checkbox.Group
                options={genres.map((genre) => ({
                  label: genre.name,
                  value: genre.id,
                }))}
                value={selectedGenres}
                onChange={(checkedValues) => setSelectedGenres(checkedValues)}
              />
              <div style={{ marginTop: '10px' }}>
                <Button type="primary" onClick={handlePreferencesSave}>
                  Save Preferences
                </Button>
              </div>
            </div>
          )}
        </Panel>
        {!isAuthor && (
          <Panel header="Become an Author" key="opt-in">
            <Button type="primary" onClick={() => setOptInModalOpen(true)}>
              Opt-in to Become an Author
            </Button>
          </Panel>
        )}
        {isAuthor && (
          <>
            <Panel header="Author Profile" key="author-profile">
              <AuthorProfile />
            </Panel>
            <Panel header="Author Books" key="author-books">
              <AuthorBooks />
            </Panel>
            <Panel header="Payout Details" key="payout-details">
              <PayoutDetails />
            </Panel>
            <Panel header="Upload Book" key="upload-book">
              <UploadBook />
            </Panel>
          </>
        )}
      </Collapse>

      {/* Modal for opt-in confirmation */}
      <Modal
        title="Become an Author"
        open={optInModalOpen}
        onOk={handleOptIn}
        onCancel={() => setOptInModalOpen(false)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>
          By opting in, you will gain access to features such as uploading
          books, managing your profile, and tracking sales. Would you like to
          proceed?
        </p>
      </Modal>
    </div>
  );
};

export default MyAccount;
