import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ConfigProvider, Layout } from 'antd';
import './App.css';
import { AuthProvider, default as AuthContext } from './context/AuthContext';
import Login from './components/Login';
import SignupForm from './components/SignupForm';
import AuthorBooks from './components/AuthorBooks';
import AboutAuthor from './components/AboutAuthor';
import Header from './components/Header';
import { MessageProvider } from './context/MessageContext';
import PayoutPage from './components/PayoutDetails';
import CheckYourEmail from './components/CheckYourEmail';
import ResetPassword from './components/ResetPassword';
import VerificationPage from './components/VerificationPage';
import UploadBook from './components/UploadBook';
import HTMLEditor from './components/HTMLEditor';
import Library from './components/Library';
import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';
import AuthorDashboard from './components/AuthorDashboard';
import AuthorProfile from './components/AuthorProfile';
import PurchaseCreditsPage from './components/PurchaseCreditsPage';
import Checkout from './components/CheckoutPage';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import MyAccount from './components/MyAccount';
import Home from './components/Home';
import BookReader from './components/BookReader';
import BookProduct from './components/BookProduct';
import BrowsingPage from './components/BrowsingPage';
import PaymentProcessingPage from './components/PaymentProcessingPage';
import BookClubLandingPage from './components/BookClubLandingPage';
import CollectionsPage from './components/CollectionsPage';

const { Header: AntHeader, Content, Footer: AntFooter } = Layout;

const AppContent = () => {
  const { user, isLoading } = useContext(AuthContext);
  const location = useLocation(); // Get current route

  if (isLoading) {
    console.log('AuthContext is loading...');
    return <div>Loading...</div>;
  }

  // Define routes where the footer should be hidden
  const noFooterRoutes = ['/reader'];

  // Check if the current route matches any no-footer routes
  const isFooterHidden = noFooterRoutes.some((path) =>
    location.pathname.startsWith(path),
  );

  return (
    <MessageProvider>
      <Layout>
        <AntHeader>
          <Header />
        </AntHeader>
        <Content>
          <Routes>
            {/* Public Routes */}
            <Route path="/html-edit" element={<HTMLEditor />} />
            <Route path="/check-your-email" element={<CheckYourEmail />} />
            <Route path="/verify-email" element={<VerificationPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/browse" element={<BrowsingPage />} />
            <Route path="/payout-details" element={<PayoutPage />} />
            <Route path="/payment-success" element={<PaymentSuccessPage />} />
            <Route path="/collections" element={<CollectionsPage />} />
            <Route
              path="/payment-pending"
              element={<PaymentProcessingPage />}
            />
            <Route path="/book/:bookId" element={<BookProduct />} />
            <Route path="/about-author/:authorId" element={<AboutAuthor />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/signup" element={<SignupForm />} />
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <Routes>
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route
                      path="author/books"
                      element={<AuthorBooks authorId={user?.id} />}
                    />
                    <Route path="reader/:bookId" element={<BookReader />} />
                    <Route path="bookclub" element={<BookClubLandingPage />} />
                    <Route path="my-account" element={<MyAccount />} />
                    <Route path="checkout" element={<Checkout />} />
                    <Route path="profile" element={<AuthorProfile />} />
                    <Route
                      path="author-dashboard"
                      element={<AuthorDashboard />}
                    />
                    <Route path="upload-book" element={<UploadBook />} />
                    <Route path="library" element={<Library />} />
                    <Route
                      path="purchase-credits"
                      element={<PurchaseCreditsPage />}
                    />
                  </Routes>
                </ProtectedRoute>
              }
            />
          </Routes>
        </Content>
        {!isFooterHidden && (
          <AntFooter>
            <Footer />
          </AntFooter>
        )}
      </Layout>
    </MessageProvider>
  );
};

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#2374ab',
          colorSecondary: '#1b5b8a',
          borderRadius: 4,
        },
      }}
    >
      <BrowserRouter>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
