import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Spin, message } from 'antd';
import { fetchFromAPI } from './api';
import AuthContext from '../context/AuthContext';
import { mockDashboardData } from '../mockData'; // Import mock data
import './AuthorDashboard.css';
import TotalSalesChart from './AuthorDashboard/TotalSalesChart';

const { Title } = Typography;

const AuthorDashboard = () => {
  const { accessToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);

  // Set to `true` to use mock data
  const useMockData = true;

  useEffect(() => {
    if (useMockData) {
      // Simulate a delay to mimic an API call
      setTimeout(() => {
        setDashboardData(mockDashboardData);
        setLoading(false);
      }, 1000);
      return;
    }

    const fetchDashboardData = async () => {
      try {
        const response = await fetchFromAPI('protected/author-dashboard', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch dashboard data: ${response.statusText}`,
          );
        }

        const data = await response.json();
        setDashboardData(data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        message.error('Unable to fetch dashboard data.');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [accessToken, useMockData]);

  if (loading) {
    return <Spin size="large" />;
  }

  if (!dashboardData) {
    return <p>No data available.</p>;
  }

  return (
    <div className="author-dashboard">
      <Title level={2} className="dashboard-title">
        Author Dashboard
      </Title>
      <Row gutter={[16, 16]} className="dashboard-metrics">
        <Col xs={24} sm={12} md={8}>
          <Card>
            <Title level={4}>Total Sales</Title>
            <p>{dashboardData.totalSales}</p>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card>
            <Title level={4}>Books Published</Title>
            <p>{dashboardData.totalBooks}</p>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card>
            <Title level={4}>Earnings</Title>
            <p>{`$${dashboardData.totalEarnings.toFixed(2)}`}</p>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="dashboard-details">
        <Col span={24}>
          <Card>
            <Title level={4}>Book Sales This Month</Title>
            {Array.isArray(dashboardData.booksSoldThisMonth) &&
            dashboardData.booksSoldThisMonth.length > 0 ? (
              <ul>
                {dashboardData.booksSoldThisMonth.map((book) => (
                  <li key={book.id}>
                    {book.title}: {book.unitsSold} units sold for $
                    {book.revenue.toFixed(2)}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No book sales this month.</p>
            )}
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="dashboard-charts">
        <Col span={24}>
          <Card>
            <Title level={4}>Total Sales Chart</Title>
            <TotalSalesChart salesData={dashboardData.salesOverMonths} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AuthorDashboard;
