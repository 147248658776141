import React, { useContext, useState } from 'react';
import { Menu, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import './Header.css';

const Header = () => {
  const { user, logout, credits } = useContext(AuthContext);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const handleLogout = () => {
    logout();
  };

  const menuItems = [
    { label: <Link to="/">Home</Link>, key: 'home' },
    { label: <Link to="/browse">Browse</Link>, key: 'browse' },
    user
      ? { label: <Link to="/library">Library</Link>, key: 'library' }
      : { label: <Link to="/signin">Login</Link>, key: 'signin' },
    user
      ? {
          label: (
            <Button type="link" onClick={handleLogout}>
              Logout
            </Button>
          ),
          key: 'logout',
        }
      : { label: <Link to="/signup">Sign Up</Link>, key: 'signup' },
    ...(user
      ? [
          { label: `Credits: ${credits}`, key: 'credAmount' },
          {
            label: <Link to="/purchase-credits">Purchase Credits</Link>,
            key: 'credPurchase',
          },
          { label: <Link to="/my-account">My Account</Link>, key: 'myAccount' },
        ]
      : []),
  ];

  return (
    <header className="header">
      <div className="header__logo">
        <Link to="/">LeafQuill</Link>
      </div>

      {/* Menu for larger screens */}
      <Menu mode="horizontal" items={menuItems} className="header__menu" />

      {/* Drawer for smaller screens */}
      <Button
        type="text"
        icon={<MenuOutlined />}
        className="header__menu-button"
        onClick={showDrawer}
      />
      <Drawer
        title="Menu"
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ padding: 0 }}
      >
        <Menu mode="vertical" items={menuItems} />
      </Drawer>
    </header>
  );
};

export default Header;
