import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Card, Typography, Button, Alert } from 'antd';
import './PaymentSuccessPage.css';

const { Title, Text } = Typography;

const PaymentSuccessPage = () => {
  const location = useLocation();
  const { credits, totalCost } = location.state || {};

  return (
    <div className="payment-success-container">
      <Card className="payment-success-card">
        <Title level={2}>Payment Received</Title>
        <Text>
          Thank you for your purchase! Your payment has been successfully
          submitted.
        </Text>
        {credits && totalCost && (
          <div className="summary">
            <Text>
              <strong>{credits}</strong> credits purchased for{' '}
              <strong>${totalCost.toFixed(2)}</strong>.
            </Text>
          </div>
        )}
        <Alert
          message="Payment Processing"
          description={
            <>
              Your payment is currently being processed. For ACH bank
              transactions, processing may take{' '}
              <strong>3-5 business days</strong>.
            </>
          }
          type="info"
          showIcon
          className="processing-alert"
        />
        <div className="button-group">
          <Link to="/library">
            <Button type="primary" size="large">
              Go to Your Library
            </Button>
          </Link>
          <Link to="/">
            <Button type="default" size="large">
              Return Home
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default PaymentSuccessPage;
