import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Select, Button, Upload, DatePicker, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { fetchFromAPI } from './api';
import AuthContext from '../context/AuthContext';
import { Editor } from '@tinymce/tinymce-react';
import './UploadBook.css';

const { Option } = Select;

const UploadBook = () => {
  const [form] = Form.useForm();
  const [genres, setGenres] = useState([]);
  const [tags, setTags] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchGenresAndTags = async () => {
      try {
        const genreResponse = await fetchFromAPI('public/genres');
        const tagResponse = await fetchFromAPI('public/tags');

        const genresData = await genreResponse.json();
        const tagsData = await tagResponse.json();

        setGenres(genresData || []);
        setTags(tagsData || []);
      } catch (error) {
        console.error('Error fetching genres or tags:', error);
      }
    };

    fetchGenresAndTags();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('price', values.price);
      formData.append('genre', values.genre);
      formData.append('description', values.description);
      formData.append('tags', JSON.stringify(values.tags));
      formData.append('publishDate', values.publishDate.format('YYYY-MM-DD'));
      formData.append('cover', values.cover.file.originFileObj);
      formData.append('epub', values.epub.file.originFileObj);
      formData.append('authorId', user.id);

      const response = await fetchFromAPI('books/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        message.success('Book uploaded successfully!');
        form.resetFields();
      } else {
        throw new Error('Failed to upload book');
      }
    } catch (error) {
      console.error(error);
      message.error('An error occurred while uploading the book.');
    }
  };

  return (
    <div className="upload-book">
      <h2>Upload a New Book</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="upload-form"
      >
        <Form.Item
          name="title"
          label="Book Title"
          rules={[{ required: true, message: 'Please enter the book title' }]}
        >
          <Input placeholder="Enter book title" />
        </Form.Item>

        <Form.Item
          name="price"
          label="Price in Credits"
          rules={[
            { required: true, message: 'Please enter the price in credits' },
          ]}
        >
          <Input type="number" placeholder="Enter price in credits" min="1" />
        </Form.Item>

        <Form.Item
          name="genre"
          label="Genre"
          rules={[{ required: true, message: 'Please select a genre' }]}
        >
          <Select placeholder="Select a genre">
            {genres.map((genre) => (
              <Option key={genre.id} value={genre.id}>
                {genre.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: 'Please enter the book description' },
          ]}
        >
          <Editor
            apiKey="8sr7afjunza36deq21h78bmfp01uztp4ldilezm5jci6bg7w"
            init={{
              height: 300,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
            }}
            onEditorChange={(content) =>
              form.setFieldsValue({ description: content })
            }
          />
        </Form.Item>

        <Form.Item name="tags" label="Tags">
          <Select mode="multiple" placeholder="Select tags">
            {tags.map((tag) => (
              <Option key={tag.id} value={tag.id}>
                {tag.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="cover"
          label="Cover Image"
          rules={[{ required: true, message: 'Please upload a cover image' }]}
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            accept="image/*"
            beforeUpload={() => false}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>

        <Form.Item
          name="epub"
          label="EPUB File"
          rules={[{ required: true, message: 'Please upload the EPUB file' }]}
        >
          <Upload
            listType="picture"
            maxCount={1}
            accept=".epub"
            beforeUpload={() => false}
          >
            <Button>Upload EPUB</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="publishDate"
          label="Publish Date"
          rules={[
            { required: true, message: 'Please select the publish date' },
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Upload Book
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UploadBook;
