import React, { useState, useEffect, useContext } from 'react';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Typography, Button, Spin, Card, Alert } from 'antd';
import { fetchFromAPI } from './api';
import AuthContext from '../context/AuthContext';
import './CheckoutPage.css';
import { message } from 'antd'; // Ensure this is imported at the top

const { Title, Text } = Typography;

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(
  'pk_test_51QNbQFB3kskbD2Vepm6Ja7rmwzG6p1lfR1Bk6us7ilbavJcnmhgAOVlI5nJ3SX6gX4dX9rH8iAI3EXpZab4QdeOE001p7rGs8I',
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { user, checkoutCredits, checkoutCost } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      setErrorMessage('Stripe.js has not loaded yet.');
      setIsProcessing(false);
      return;
    }

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-success`,
        },
        redirect: 'if_required', // Prevents automatic redirection
      });

      if (error) {
        setErrorMessage(error.message);
        setIsProcessing(false);
        return;
      }

      console.log('Result: ', paymentIntent);

      if (paymentIntent?.status === 'succeeded') {
        try {
          await fetchFromAPI('protected/confirm-purchase', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: user.id,
              credits: checkoutCredits,
              amount: checkoutCost,
            }),
          });
          navigate('payment-success');
        } catch (apiError) {
          console.error('Error confirming purchase:', apiError);
          message.error('Failed to confirm purchase.');
        }
      } else if (paymentIntent.status === 'processing') {
        navigate('payment-pending');
      } else {
        navigate('payment-pending');
      }
    } catch (error) {
      console.error('Error during payment confirmation:', error);
      setErrorMessage('An error occurred during payment. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <Card className="summary-card">
        <Title level={3}>Summary</Title>
        <Text>Total Credits: {checkoutCredits}</Text>
        <br />
        <Text>
          Total Cost: ${checkoutCost ? checkoutCost.toFixed(2) : '0.00'}
        </Text>
      </Card>
      <div className="payment-element-container">
        <PaymentElement />
        {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
      </div>
      <Button
        type="primary"
        htmlType="submit"
        disabled={!stripe || isProcessing}
        loading={isProcessing}
        className="checkout-button"
      >
        {isProcessing ? 'Processing...' : 'Pay Now'}
      </Button>
    </form>
  );
};

const CheckoutPage = () => {
  const { user, checkoutCredits, checkoutCost, accessToken } =
    useContext(AuthContext);
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user || !checkoutCredits || !checkoutCost) {
      console.error(
        'Missing context values: ensure PurchaseCreditsPage set them.',
      );
      setLoading(false);
      return;
    }

    const fetchClientSecret = async () => {
      try {
        const response = await fetchFromAPI('protected/purchase-credits', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            userId: user.id,
            credits: checkoutCredits,
            amount: checkoutCost,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch client secret');
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error('Error fetching client secret:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClientSecret();
  }, [user, checkoutCredits, checkoutCost, accessToken]);

  if (loading) {
    return (
      <div className="checkout-page">
        <Spin size="large" />
      </div>
    );
  }

  if (!clientSecret) {
    return (
      <div className="checkout-page">
        <Alert
          message="Error"
          description="Unable to initialize payment. Please try again later."
          type="error"
          showIcon
        />
      </div>
    );
  }

  const stripeOptions = { clientSecret };

  return (
    <div className="checkout-page">
      <Title level={2}>Checkout</Title>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};

export default CheckoutPage;
