// src/components/AboutAuthor.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './AboutAuthor.css';
import { fetchFromAPI } from './api';

const AboutAuthor = () => {
  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { authorId } = useParams();

  //const location = useLocation();

  // Extract authorId from query parameters

  useEffect(() => {
    const fetchAuthor = async () => {
      try {
        const response = await fetchFromAPI(`public/authors/${authorId}`);
        if (response.ok) {
          const data = await response.json();
          setAuthor(data);
        } else {
          setError('Failed to fetch author details.');
        }
      } catch (err) {
        console.error('Error fetching author details:', err);
        setError('An error occurred while fetching author details.');
      } finally {
        setLoading(false);
      }
    };

    if (authorId) {
      fetchAuthor();
    } else {
      setError('No author ID provided.');
      setLoading(false);
    }
  }, [authorId]);

  if (loading) return <p>Loading author details...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div className="about-author">
      <div className="author-header">
        <div className="author-image">
          <img
            src={author.profile_image_url || '/default-profile.jpg'}
            alt={`${author.name}`}
          />
        </div>
        <div className="author-info">
          <h1>{author.name}</h1>
          <div className="social-links">
            {author.instagram_link && (
              <a
                href={author.instagram_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            )}
            {author.x_link && (
              <a href={author.x_link} target="_blank" rel="noopener noreferrer">
                X
              </a>
            )}
            {author.facebook_link && (
              <a
                href={author.facebook_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="author-about">
        <h2>About {author.name}</h2>
        <p>{author.about}</p>
      </div>
    </div>
  );
};

export default AboutAuthor;
