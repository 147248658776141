import React from 'react';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isLoading, user } = useContext(AuthContext);

  // Wait for the loading to complete before rendering
  if (isLoading) {
    return <div>Loading...</div>; // Show a loading state or spinner
  }
  console.log('User: ' + user);
  console.log('Loading: ' + isLoading);
  if (!user) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export default ProtectedRoute;
