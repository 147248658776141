import React from 'react';
import { Layout, Space, Typography } from 'antd';
import './Footer.css';

const { Footer } = Layout;
const { Text } = Typography;

const AppFooter = () => {
  return (
    <Footer className="footer">
      <Space direction="vertical" align="center" className="footer-content">
        <Space>
          <a href="/about" className="footer-link">
            About Us
          </a>
          <a href="/privacy" className="footer-link">
            Privacy Policy
          </a>
          <a href="/terms" className="footer-link">
            Terms of Service
          </a>
        </Space>
        <Text className="footer-credits">
          © 2024 LeafQuill. All rights reserved.
        </Text>
      </Space>
    </Footer>
  );
};

export default AppFooter;
