import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Collapse, message } from 'antd';
import AuthContext from '../context/AuthContext';
import { fetchFromAPI } from './api';
import './Login.css';

const { Panel } = Collapse;

const Login = () => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState('');
  const { login, fetchAndCacheLibrary, accessToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    try {
      const response = await fetchFromAPI('public/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(values),
      });
      const data = await response.json();

      if (response.ok) {
        await login(
          { id: data.id, username: data.username },
          data.isAuthor,
          data.is_admin,
          data.credits,
          data.accessToken,
        );

        let attempts = 0;
        const maxAttempts = 100; // Maximum number of attempts
        while (accessToken !== data.accessToken && attempts < maxAttempts) {
          await new Promise((resolve) => setTimeout(resolve, 10)); // Wait 10ms
          attempts++;
        }
        await fetchAndCacheLibrary(data.username);
        navigate('/');
        message.success('Login successful: ');
      } else {
        message.error(data.message || 'Invalid login credentials');
      }
    } catch (error) {
      console.error('Login error:', error.message);
      message.error('An unexpected error occurred. Please try again.');
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await fetchFromAPI('public/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        message.success('A recovery link has been sent to your email.');
      } else {
        const data = await response.json();
        message.error(data.message || 'Failed to send recovery email.');
      }
    } catch (error) {
      console.error('Forgot password error:', error.message);
      message.error('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <Form
        form={form}
        onFinish={handleLogin}
        layout="vertical"
        className="login-form"
      >
        <h2>Login</h2>
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please enter your username' }]}
        >
          <Input placeholder="Enter your username" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-button"
            block
          >
            Login
          </Button>
        </Form.Item>
      </Form>
      <Collapse ghost>
        <Panel header="Forgot username or password?" key="1">
          <Form layout="vertical" onFinish={handleForgotPassword}>
            <Form.Item
              label="Email Address"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please enter a valid email',
                },
              ]}
            >
              <Input
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="retrieve-button"
                block
              >
                Retrieve
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
      <p className="signup-link">
        Don’t have an account? <a href="/signup">Sign up here</a>.
      </p>
    </div>
  );
};

export default Login;
