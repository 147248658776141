const DB_NAME = 'BookLibrary';
const STORE_NAME = 'EPUBs';
const DB_VERSION = 1;

// Open or initialize IndexedDB
const openDatabase = () =>
  new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Create an object store for EPUB files
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const objectStore = db.createObjectStore(STORE_NAME, {
          keyPath: 'bookId', // Use bookId as the primary key
        });
        objectStore.createIndex('title', 'title', { unique: false });
      }
    };

    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });

// Save an EPUB to IndexedDB
export const saveEPUBToDB = async (bookId, blob, metadata) => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);

    const record = {
      bookId,
      blob,
      ...metadata, // Include metadata like title, author, etc.
    };

    const request = store.put(record);

    request.onsuccess = () => resolve(true);
    request.onerror = (event) => reject(event.target.error);
  });
};

// Retrieve an EPUB from IndexedDB
export const getEPUBFromDB = async (bookId) => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readonly');
    const store = transaction.objectStore(STORE_NAME);

    const request = store.get(bookId);

    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });
};

// Check if an EPUB is already downloaded
export const isEPUBDownloaded = async (bookId) => {
  const record = await getEPUBFromDB(bookId);
  return !!record; // Return true if the record exists
};

// Delete an EPUB from IndexedDB
export const deleteEPUBFromDB = async (bookId) => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);

    const request = store.delete(bookId);

    request.onsuccess = () => resolve(true);
    request.onerror = (event) => reject(event.target.error);
  });
};
