import React, { useEffect, useState, useContext } from 'react';
import { Table, Button, Typography, message, Spin } from 'antd';
import { fetchFromAPI } from './api';
import AuthContext from '../context/AuthContext';
import './AuthorBooks.css';

const { Title } = Typography;

const AuthorBooks = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, accessToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await fetchFromAPI(
          `protected/authorbooks?authorId=${user.id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (response.ok) {
          const data = await response.json();
          setBooks(data);
        } else {
          throw new Error(`Failed to fetch books: ${response.statusText}`);
        }
      } catch (error) {
        message.error('Unable to fetch books');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBooks();
  }, [user.id, accessToken]);

  const handlePublish = async (bookId) => {
    try {
      const response = await fetchFromAPI(`protected/books/${bookId}/publish`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        message.success('Book published successfully');
        setBooks((prevBooks) =>
          prevBooks.map((book) =>
            book.id === bookId ? { ...book, published: true } : book,
          ),
        );
      } else {
        throw new Error(`Failed to publish book: ${response.statusText}`);
      }
    } catch (error) {
      message.error('Failed to publish book');
      console.error(error);
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Price (Credits)',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Published',
      dataIndex: 'published',
      key: 'published',
      render: (published) =>
        published ? (
          <Button className="published" disabled>
            Published
          </Button>
        ) : (
          'Not Published'
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          {!record.published && (
            <Button
              type="primary"
              onClick={() => handlePublish(record.id)}
              style={{ marginRight: 10 }}
            >
              Publish
            </Button>
          )}
          <Button type="default">
            <a
              href={`/books/${record.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          </Button>
        </>
      ),
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="author-books">
      <Title level={2}>Your Books</Title>
      <Table
        columns={columns}
        dataSource={books}
        rowKey="id"
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};

export default AuthorBooks;
