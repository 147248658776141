import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  Card,
  Descriptions,
  Avatar,
  Button,
  Form,
  Input,
  message,
  Spin,
} from 'antd';
import { fetchFromAPI } from './api';
import './AuthorProfile.css';
import AuthContext from '../context/AuthContext';

const AuthorProfile = () => {
  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const { user, accessToken } = useContext(AuthContext);
  const [form] = Form.useForm();

  // Memoize the fetchAuthorProfile function
  const fetchAuthorProfile = useCallback(async () => {
    try {
      const response = await fetchFromAPI(`public/authors/${user.id}`, {});
      if (response.ok) {
        const data = await response.json();
        setAuthor(data);
      } else {
        throw new Error(
          `Failed to load author profile: ${response.statusText}`,
        );
      }
    } catch (error) {
      message.error('Unable to fetch author profile.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [user.id]);

  useEffect(() => {
    fetchAuthorProfile();
  }, [fetchAuthorProfile]);

  const handleEdit = () => {
    setEditing(true);
    form.setFieldsValue({
      userId: author.id, // Ensure userId is part of the form
      name: author.name,
      about: author.about,
      instagram_link: author.instagram_link,
      x_link: author.x_link,
      facebook_link: author.facebook_link,
    });
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleSave = async (values) => {
    setLoading(true);

    try {
      const payload = {
        ...values,
        userId: author.id,
      };

      const response = await fetchFromAPI(`protected/authors/${user.id}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        message.success('Profile updated successfully');
        setEditing(false);

        // Refetch the updated author profile
        await fetchAuthorProfile();
      } else {
        throw new Error(`Failed to update profile: ${response.statusText}`);
      }
    } catch (error) {
      message.error('Failed to update profile.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!author) {
    return <div>Author profile not found.</div>;
  }

  return (
    <Card
      className="author-profile-card"
      title={
        <div className="author-profile-header">
          <Avatar
            size={80}
            src={author.profile_image_url || '/default-avatar.png'}
          />
          <span className="author-name">{`${author.name}`}</span>
        </div>
      }
      bordered={false}
    >
      {editing ? (
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Biography" name="about">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Instagram Link" name="instagram_link">
            <Input />
          </Form.Item>
          <Form.Item label="X Link" name="x_link">
            <Input />
          </Form.Item>
          <Form.Item label="Facebook Link" name="facebook_link">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: 10 }}
            >
              Save
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Form.Item>
        </Form>
      ) : (
        <>
          <Descriptions column={1} layout="vertical">
            <Descriptions.Item label="Name">{`${author.name}`}</Descriptions.Item>
            <Descriptions.Item label="Biography">
              {author.about || 'No biography available.'}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
              {author.country}
            </Descriptions.Item>
            <Descriptions.Item label="Social Media">
              {author.instagram_link && (
                <a
                  href={author.instagram_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              )}
              {author.x_link && (
                <>
                  {' | '}
                  <a
                    href={author.x_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    X
                  </a>
                </>
              )}
              {author.facebook_link && (
                <>
                  {' | '}
                  <a
                    href={author.facebook_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Button type="primary" onClick={handleEdit}>
            Edit Profile
          </Button>
        </>
      )}
    </Card>
  );
};

export default AuthorProfile;
