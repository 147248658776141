import React, { useState, useEffect } from 'react';
import { Card, Image, Badge, Button, Tooltip, Spin } from 'antd';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import './BookTile.css';
import { fetchFromAPI } from './api';
import {
  saveEPUBToDB,
  isEPUBDownloaded,
  deleteEPUBFromDB,
} from '../util/indexedDB';

const { Meta } = Card;

// Handle the EPUB download
const handleDownload = async (bookId, title, setIsDownloaded, setIsDownloading) => {
  console.log(`Starting download for book ID: ${bookId}`);
  setIsDownloading(true); // Start the spinner
  try {
    const response = await fetchFromAPI(`protected/download/${bookId}`, {
      method: 'GET',
      credentials: 'include', // Send cookies for authentication
    });

    if (!response.ok) {
      const errorResponse = await response.text();
      console.error(
        `Failed to download book ID: ${bookId}. Response: ${errorResponse}`,
      );
      throw new Error(`Download failed with status: ${response.status}`);
    }

    const blob = await response.blob();
    console.log(`Book ID ${bookId} downloaded successfully.`);

    // Save to IndexedDB with metadata
    await saveEPUBToDB(bookId, blob, { title });
    console.log(`Book ID ${bookId} saved to IndexedDB.`);

    setIsDownloaded(true); // Update the component state
    console.log(`Book ID ${bookId} marked as downloaded.`);
  } catch (error) {
    console.error('Error downloading book:', error.message);
  } finally {
    setIsDownloading(false); // Stop the spinner
  }
};

// Handle removing a downloaded book
const handleRemoveDownload = async (bookId, setIsDownloaded) => {
  try {
    await deleteEPUBFromDB(bookId); // Use the correct function from indexedDB.js
    console.log(`Book ID ${bookId} removed from IndexedDB.`);
    setIsDownloaded(false); // Update the state to reflect the removal
  } catch (error) {
    console.error(
      `Failed to remove book ID ${bookId} from IndexedDB:`,
      error.message,
    );
  }
};

// BookTile component
const BookTile = ({
  book = {},
  hidePrice = false,
  onClick,
  isPurchased = false,
}) => {
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false); // Spinner state

  const { title, name, cover_image_url, cover_image_alt, price, id } = book;

  useEffect(() => {
    if (isPurchased) {
      isEPUBDownloaded(id).then(setIsDownloaded);
    }
  }, [id, isPurchased]);

  return (
    <Badge.Ribbon
      text={isPurchased ? (isDownloaded ? 'Downloaded' : 'Purchased') : ''}
      color={isDownloaded ? 'green' : 'blue'}
      hidden={!isPurchased}
    >
      <Card
        hoverable={!isDownloaded}
        cover={
          <div
            className={`book-tile ${!isDownloaded ? 'book-tile-muted' : ''}`}
          >
            <Image
              src={cover_image_url || 'covers/generic.jpg'}
              alt={cover_image_alt || 'Book cover'}
              style={{ width: '100%', height: 'auto' }}
              preview={false}
              className="book-tile-image"
            />
          </div>
        }
        onClick={onClick}
        className={`book-tile-card ${isPurchased ? 'book-tile-purchased' : ''}`}
      >
        <Meta
          title={title}
          description={`${name || ''}`}
          className="book-tile-meta"
        />
        {!hidePrice && price !== undefined && !isNaN(price) && (
          <div className="book-price-wrapper">
            <p className="book-price">Credits: {Math.floor(price)}</p>
          </div>
        )}
        {isPurchased && (
          <div className="book-actions">
            {!isDownloaded ? (
              <Tooltip title="Download EPUB">
                <Button
                  icon={
                    isDownloading ? (
                      <Spin size="small" />
                    ) : (
                      <DownloadOutlined />
                    )
                  }
                  shape="circle"
                  className="book-tile-download-button"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering card click
                    handleDownload(id, title, setIsDownloaded, setIsDownloading);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Download">
                <Button
                  icon={<DeleteOutlined />}
                  shape="circle"
                  className="book-tile-remove-button"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering card click
                    handleRemoveDownload(id, setIsDownloaded);
                  }}
                />
              </Tooltip>
            )}
          </div>
        )}
      </Card>
    </Badge.Ribbon>
  );
};

export default BookTile;
