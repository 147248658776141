import React, { useState, useContext } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Typography,
  Collapse,
  message,
} from 'antd';
import AuthContext from '../context/AuthContext';
import {
  Elements,
  useStripe,
  useElements,
  IbanElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { fetchFromAPI } from './api';
import './PayoutDetails.css';

const { Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const stripePromise = loadStripe('your-publishable-key-here');

const BankDetailsForm = () => {
  const { user, accessToken } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [accountHolderName, setAccountHolderName] = useState('');
  const [country, setCountry] = useState('DE');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [amount1, setAmount1] = useState('');
  const [amount2, setAmount2] = useState('');

  const handleSaveBankDetails = async () => {
    setIsProcessing(true);

    if (!stripe || !elements) {
      message.error('Stripe.js has not loaded yet.');
      setIsProcessing(false);
      return;
    }

    try {
      let result;
      if (country === 'US') {
        result = await stripe.createToken('bank_account', {
          country,
          currency: 'usd',
          routing_number: routingNumber,
          account_number: accountNumber,
          account_holder_name: accountHolderName,
          account_holder_type: 'individual',
        });
      } else {
        result = await stripe.createToken(elements.getElement(IbanElement), {
          account_holder_name: accountHolderName,
          account_holder_type: 'individual',
        });
      }

      if (result.error) {
        message.error(result.error.message);
      } else {
        const response = await fetchFromAPI('protected/save-bank-details', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            userId: user.id,
            token: result.token.id,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to save bank details');
        }

        message.success('Bank details saved successfully!');
      }
    } catch (error) {
      message.error(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleVerifyAmounts = () => {
    message.info('Verify amounts functionality is under construction.');
  };

  return (
    <Form layout="vertical" className="payout-details-form">
      <Title level={3}>Payout Details</Title>

      <Collapse defaultActiveKey={['1']}>
        <Panel header="Bank Information" key="1">
          <Form.Item label="Account Holder Name" required>
            <Input
              value={accountHolderName}
              onChange={(e) => setAccountHolderName(e.target.value)}
              placeholder="John Doe"
            />
          </Form.Item>
          <Form.Item label="Country" required>
            <Select value={country} onChange={(value) => setCountry(value)}>
              <Option value="DE">Germany</Option>
              <Option value="FR">France</Option>
              <Option value="ES">Spain</Option>
              <Option value="US">United States</Option>
            </Select>
          </Form.Item>
          {country === 'US' ? (
            <>
              <Form.Item label="Routing Number" required>
                <Input
                  value={routingNumber}
                  onChange={(e) => setRoutingNumber(e.target.value)}
                  placeholder="123456789"
                />
              </Form.Item>
              <Form.Item label="Account Number" required>
                <Input
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  placeholder="123456789123"
                />
              </Form.Item>
            </>
          ) : (
            <Form.Item label="IBAN" required>
              <IbanElement
                options={{
                  supportedCountries: ['SEPA'],
                  placeholderCountry: country,
                  style: {
                    base: { fontSize: '16px', color: '#424770' },
                    invalid: { color: '#9e2146' },
                  },
                }}
              />
            </Form.Item>
          )}
          <Button
            type="primary"
            onClick={handleSaveBankDetails}
            loading={isProcessing}
            className="save-button"
          >
            {isProcessing ? 'Processing...' : 'Save Bank Details'}
          </Button>
        </Panel>
      </Collapse>

      <Title level={4}>Verification Amounts</Title>
      <Form.Item label="First Verification Amount" required>
        <Input
          value={amount1}
          onChange={(e) => setAmount1(e.target.value)}
          placeholder="e.g., 0.12"
        />
      </Form.Item>
      <Form.Item label="Second Verification Amount" required>
        <Input
          value={amount2}
          onChange={(e) => setAmount2(e.target.value)}
          placeholder="e.g., 0.34"
        />
      </Form.Item>

      <Button
        type="default"
        onClick={handleVerifyAmounts}
        className="verify-button"
        style={{ marginTop: '10px' }}
      >
        Verify Amounts
      </Button>
    </Form>
  );
};

const PayoutDetails = () => {
  return (
    <Elements stripe={stripePromise}>
      <BankDetailsForm />
    </Elements>
  );
};

export default PayoutDetails;
