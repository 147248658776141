import React, { useEffect, useState } from 'react';
import { Collapse, Typography, Spin, Alert, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { fetchFromAPI } from './api';

const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

const AnnotationWidget = () => {
  const [annotations, setAnnotations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize React Router's navigation hook

  useEffect(() => {
    const loadAnnotations = async () => {
      try {
        console.log('Fetching annotations...');
        const rawData = await fetchFromAPI('protected/all/annotations');
        console.log('');
        const data = await rawData.json();

        // Group annotations by book title
        const groupedAnnotations = data.reduce((acc, annotation) => {
          const bookTitle = annotation.book_title || 'Unknown Title';
          if (!acc[bookTitle]) {
            acc[bookTitle] = [];
          }
          acc[bookTitle].push(annotation);
          return acc;
        }, {});

        setAnnotations(groupedAnnotations);
      } catch (err) {
        console.error('Failed to fetch annotations:', err);
        setError('Failed to load annotations. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadAnnotations();
  }, []);

  const handleNavigateToAnnotation = (bookId, cfiRange) => {
    navigate(`/reader/${bookId}?location=${encodeURIComponent(cfiRange)}`);
  };

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  return (
    <Collapse>
      {Object.keys(annotations).length > 0 ? (
        Object.entries(annotations).map(([bookTitle, bookAnnotations]) => (
          <Panel header={bookTitle} key={bookTitle}>
            {bookAnnotations.map((annotation, index) => (
              <div key={index} style={{ marginBottom: '16px' }}>
                <Paragraph>
                  <Text strong>Highlight:</Text> {annotation.text || 'N/A'}
                </Paragraph>
                <Paragraph>
                  <Text strong>Note:</Text>{' '}
                  {annotation.note || 'No note added.'}
                </Paragraph>
                <Button
                  type="link"
                  onClick={() =>
                    handleNavigateToAnnotation(
                      annotation.book_id,
                      annotation.cfi_range,
                    )
                  }
                >
                  Go to Annotation
                </Button>
              </div>
            ))}
          </Panel>
        ))
      ) : (
        <Paragraph>No annotations found.</Paragraph>
      )}
    </Collapse>
  );
};

export default AnnotationWidget;
