import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Select,
  Typography,
  Button,
  Row,
  Col,
  message,
} from 'antd';
import AuthContext from '../context/AuthContext';
import './PurchaseCreditsPage.css';

const { Title, Text } = Typography;
const { Option } = Select;

const PurchaseCreditsPage = () => {
  const navigate = useNavigate();
  const { checkout } = useContext(AuthContext);

  const [creditCounts, setCreditCounts] = useState({
    5: 0,
    20: 0,
    40: 0,
  });

  const totalCredits = Object.entries(creditCounts).reduce(
    (sum, [key, value]) => sum + parseInt(key) * value,
    0,
  );

  const totalCost = Object.entries(creditCounts).reduce(
    (sum, [key, value]) => sum + value * parseInt(key),
    0,
  );

  const handleCreditChange = (type, value) => {
    setCreditCounts((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleCheckout = () => {
    if (totalCredits === 0) {
      message.warning('Please select at least one credit package.');
      return;
    }
    console.log('Totals: ' + totalCredits + ' ' + totalCost);
    checkout(totalCredits, totalCost);
    navigate('/checkout');
  };

  const dataSource = [5, 20, 40].map((type) => ({
    key: type,
    package: `${type} Credits`,
    price: `$${type}`,
    quantity: (
      <Select
        value={creditCounts[type]}
        onChange={(value) => handleCreditChange(type, value)}
        style={{ width: 80 }}
      >
        {[...Array(11).keys()].map((i) => (
          <Option key={i} value={i}>
            {i}
          </Option>
        ))}
      </Select>
    ),
  }));

  const columns = [
    {
      title: 'Credit Package',
      dataIndex: 'package',
      key: 'package',
    },
    {
      title: 'Price per Package',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];

  return (
    <Row justify="center" style={{ marginTop: 20 }}>
      <Col xs={22} sm={20} md={16} lg={12}>
        <Card>
          <Title level={2} style={{ textAlign: 'center' }}>
            Purchase Credits
          </Title>

          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            style={{ marginBottom: 20 }}
          />

          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Text strong>Total Credits: </Text>
            <Text>{totalCredits}</Text>
            <br />
            <Text strong>Total Cost: </Text>
            <Text>${totalCost.toFixed(2)}</Text>
          </div>

          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Button
              type="primary"
              size="large"
              onClick={handleCheckout}
              disabled={totalCredits === 0}
            >
              Checkout
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default PurchaseCreditsPage;
