import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Card, Button, Image, Typography, message, Spin, Badge } from 'antd';
import AuthContext from '../context/AuthContext';
import { fetchFromAPI } from './api';
import './BookProduct.css';

const { Title, Paragraph } = Typography;

const BookProduct = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, accessToken, setCredits } = useContext(AuthContext);
  const { bookId } = useParams();
  const [book, setBook] = useState(location.state || null);
  const [loading, setLoading] = useState(true);
  const [isPurchased, setIsPurchased] = useState(false);

  useEffect(() => {
    const cachedLibrary = JSON.parse(
      localStorage.getItem('userLibrary') || '[]',
    );
    const purchased = cachedLibrary.some((libBook) => libBook.id === bookId);
    setIsPurchased(purchased);

    if (!book) {
      fetchFromAPI(`public/books/${bookId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch book: ${response.statusText}`);
          }
          return response.json();
        })
        .then((data) => setBook(data))
        .catch((error) => {
          console.error('Error fetching book details:', error);
          message.error('Failed to load book details');
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [book, bookId]);

  if (loading) return <Spin size="large" />;

  if (!book) return <p>Book not found.</p>;

  const {
    title,
    description,
    price,
    name,
    author,
    cover_image_url,
    cover_image_alt,
  } = book;

  const handlePurchase = async () => {
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetchFromAPI('protected/purchase', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          userId: user.id,
          bookId: book.id,
          purchasePrice: book.price,
        }),
      });

      if (response.ok) {
        message.success('Purchase successful!');
        setIsPurchased(true); // Update the purchased state
        // Update user credits
        const data = await response.json();
        const { remainingCredits } = data;
        setCredits(remainingCredits);
      } else {
        const data = await response.json();
        message.error(`Purchase failed: ${data.message}`);
      }
    } catch (error) {
      console.error('Error during purchase:', error);
      message.error('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="book-product">
      {isPurchased ? (
        <Badge.Ribbon text="Purchased" color="green">
          <Card className="book-product-card" bordered={false}>
            <div className="book-product-content">
              <Image
                src={cover_image_url || '/default-cover.jpg'}
                alt={cover_image_alt || `${title} cover`}
                style={{ width: '300px', height: 'auto', objectFit: 'contain' }}
                className="book-product-image"
                preview={false}
              />
              <div className="book-product-details">
                <Title level={2}>{title}</Title>
                <Title level={4}>
                  By{' '}
                  <a href={`/about-author/${author}`}>
                    {`${name}` || 'Unknown Author'}
                  </a>
                </Title>
                <Paragraph className="book-description">
                  {description}
                </Paragraph>
                <Paragraph className="book-price">You own this book</Paragraph>
              </div>
            </div>
          </Card>
        </Badge.Ribbon>
      ) : (
        <Card className="book-product-card" bordered={false}>
          <div className="book-product-content">
            <Image
              src={cover_image_url || '/default-cover.jpg'}
              alt={cover_image_alt || `${title} cover`}
              className="book-product-image"
              preview={false}
            />
            <div className="book-product-details">
              <Title level={2}>{title}</Title>
              <Title level={4}>
                By{' '}
                <a href={`/about-author/${author}`}>
                  {`${name}` || 'Unknown Author'}
                </a>
              </Title>
              <Paragraph className="book-description">{description}</Paragraph>
              <Paragraph className="book-price">
                {price !== undefined && !isNaN(price)
                  ? `Credits: ${price}`
                  : 'Price not available'}
              </Paragraph>
              <Button
                type="primary"
                size="large"
                onClick={user ? handlePurchase : () => navigate('/login')}
              >
                {user ? 'Purchase' : 'Login to Purchase'}
              </Button>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default BookProduct;
