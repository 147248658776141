import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Typography, Spin, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import BookTile from './BookTile';
import './Library.css';
import AuthContext from '../context/AuthContext';

const { Title } = Typography;

const Library = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { fetchAndCacheLibrary, user } = useContext(AuthContext);

  useEffect(() => {
    const loadLibraryFromCache = async () => {
      try {
        setLoading(true);

        // Optionally refresh the library from the backend
        await fetchAndCacheLibrary(user.username);

        // Fetch library from localStorage
        const cachedLibrary = JSON.parse(
          localStorage.getItem('userLibrary') || '[]',
        );
        console.log('User: ' + user.username);
        setBooks(cachedLibrary);
      } catch (error) {
        message.error('Failed to load your library.');
        console.error('Error loading library:', error);
      } finally {
        setLoading(false);
      }
    };

    loadLibraryFromCache();
  }, [fetchAndCacheLibrary, user.username]);

  const handleBookClick = (bookId) => {
    navigate(`/reader/${bookId}`);
  };

  return (
    <div className="library-page">
      <Title level={2} className="library-title">
        My Library
      </Title>
      {loading ? (
        <Spin size="large" />
      ) : books.length > 0 ? (
        <Row gutter={[16, 16]} justify="center">
          {books.map((book) => (
            <Col key={book.id} xs={24} sm={12} md={8} lg={6}>
              <BookTile
                book={book}
                hidePrice={true} // Ensure prices and badges are hidden in the library
                onClick={() => handleBookClick(book.id)}
                isPurchased={true} // Always mark books in the library as purchased
              />
            </Col>
          ))}
        </Row>
      ) : (
        <p className="no-books-message">Your library is empty.</p>
      )}
    </div>
  );
};

export default Library;
