import { getAuthContext } from '../context/AuthContext';

// Updated api.js
const API_BASE_URL =
  process.env.REACT_APP_API_URL || 'https://devapi.leafquill.com/';

export const fetchFromAPI = async (
  endpoint,
  options = {},
  authContext = null,
) => {
  const context = authContext || getAuthContext();

  const { accessToken, logout, refreshAccessToken } = context;
  // console.log('Initial token: ' + accessToken);
  let headers = { ...options.headers };
  if (accessToken && accessToken !== null) {
    
    headers.Authorization = `Bearer ${accessToken}`;
  }

  try {
    let response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      headers,
    });

    if (response.ok) {
      return response;
    }

    if (
      (response.status === 401 || response.status === 403) &&
      context !== null
    ) {
      
      const newToken = await refreshAccessToken();
      
      if (newToken) {
        headers.Authorization = `Bearer ${newToken}`;
        response = await fetch(`${API_BASE_URL}${endpoint}`, {
          ...options,
          headers,
        });

        if (response.ok) {
          return response;
        }
      }

      
      logout();
    }

    throw new Error(`API request failed: ${response.status}`);
  } catch (error) {
    console.error('API request error:', error);
    throw error;
  }
};
