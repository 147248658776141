import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const TotalSalesChart = ({ salesData }) => {
  // Log salesData to verify its structure
  console.log('Sales Data for Chart:', salesData);

  // Validate data and provide fallback
  const validSalesData =
    Array.isArray(salesData) && salesData.length > 0 ? salesData : [];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={validSalesData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="sales" stroke="#2374ab" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TotalSalesChart;
