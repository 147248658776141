import React, { useEffect, useState, useContext } from 'react';
import { Typography, message, Spin } from 'antd';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import BookSlider from './BookSlider';
import HTMLWidget from './HTMLWidget';
import AnnotationWidget from './AnnotationWidget';
import { fetchFromAPI } from './api';
import './Home.css';

const { Title } = Typography;

const Home = () => {
  const [featuredBooks, setFeaturedBooks] = useState([]);
  const { user } = useContext(AuthContext);
  const [library, setLibrary] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch library from localStorage
    const cachedLibrary = JSON.parse(
      localStorage.getItem('userLibrary') || '[]',
    );
    setLibrary(cachedLibrary);

    const fetchBooks = async () => {
      try {
        const response = await fetchFromAPI('public/books');
        if (!response.ok) {
          throw new Error(`Failed to fetch books: ${response.statusText}`);
        }
        const data = await response.json();
        setFeaturedBooks(data);
      } catch (error) {
        console.error('Error fetching featured books:', error.message);
        message.error('Failed to load featured books');
      } finally {
        setLoading(false);
      }
    };

    fetchBooks();
  }, []);

  const handleBookClick = (bookId) => {
    navigate(`/book/${bookId}`);
  };

  const isBookPurchased = (bookId) => {
    return library.some((book) => book.id === bookId);
  };

  return (
    <div className="home">
      <HTMLWidget file="welcome.html" />

      <div className="home-section">
        <Title level={2} className="home-title">
          Featured Books
        </Title>
        {loading ? (
          <Spin size="large" />
        ) : (
          <BookSlider
            books={featuredBooks.map((book) => ({
              ...book,
              isPurchased: isBookPurchased(book.id), // Add purchased status
            }))}
            onBookClick={handleBookClick} // Allow book clicks
          />
        )}
      </div>

      <div className="home-section">
        <Title level={2} className="home-title">
          Top Picks for You
        </Title>
        {featuredBooks.length > 0 ? (
          <BookSlider
            books={featuredBooks.map((book) => ({
              ...book,
              isPurchased: isBookPurchased(book.id), // Add purchased status
            }))}
            onBookClick={handleBookClick} // Allow book clicks
          />
        ) : (
          <p>No top picks available.</p>
        )}
      </div>
      {user && (
        <div className="home-section">
          <Title level={2} className="home-title">
            Your Annotations
          </Title>
          <AnnotationWidget />
        </div>
      )}
    </div>
  );
};

export default Home;
