import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ePub from 'epubjs';
import { Slider, Typography, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './BookReader.css';
import AuthContext from '../context/AuthContext';
import debounce from 'lodash.debounce';
import { fetchFromAPI } from './api';

const { Text } = Typography;
const API_BASE_URL =
  process.env.REACT_APP_API_URL || 'https://devapi.leafquill.com/';

const BookReader = () => {
  const { bookId } = useParams();
  const { search } = useLocation();
  const viewerRef = useRef(null);
  const renditionRef = useRef(null);
  const bookRef = useRef(null);
  const highlightTracker = useRef(new Set());
  const [fontSize, setFontSize] = useState(16);
  const { accessToken, user } = useContext(AuthContext);

  const saveProgress = debounce(async (location) => {
    try {
      const response = await fetchFromAPI(`protected/reader-progress`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          bookId,
          userId: user.id,
          lastReadPosition: location,
        }),
      });
      if (!response.ok) {
        console.error('Failed to save progress', await response.text());
      }
    } catch (err) {
      console.error('Error saving progress:', err);
    }
  }, 5000);

  const fetchProgress = useCallback(async () => {
    try {
      const response = await fetchFromAPI(
        `protected/reader-progress?userId=${user.id}&bookId=${bookId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      if (response.ok) {
        const { lastReadPosition } = await response.json();
        return lastReadPosition;
      }
    } catch (err) {
      console.error('Error fetching progress:', err);
    }
    return null;
  }, [user.id, bookId, accessToken]);

  const fetchHighlights = useCallback(async () => {
    try {
      console.log('Fetching highlights for book:', bookId);
      const response = await fetchFromAPI(`protected/annotations/${bookId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const annotations = await response.json();
        console.log('Fetched annotations:', annotations);

        annotations.forEach((annotation) => {
          if (!highlightTracker.current.has(annotation.cfi_range)) {
            renditionRef.current.annotations.add(
              'highlight',
              annotation.cfi_range,
              {},
              null,
              'highlight',
            );
            highlightTracker.current.add(annotation.cfi_range);
            console.log(`Highlight added for range: ${annotation.cfi_range}`);
          }
        });
      } else {
        console.error('Failed to fetch highlights:', await response.text());
      }
    } catch (error) {
      console.error('Error fetching highlights:', error);
    }
  }, [bookId, accessToken]);

  const saveAnnotation = useCallback(
    async (cfiRange, text, note = '') => {
      console.log('Saving annotation:', { cfiRange, text, note }); // Debug log
      console.log('Book ID:', bookId); // Debug log
      console.log('User ID:', user.id); // Debug log

      try {
        const response = await fetchFromAPI(`protected/annotations`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            bookId,
            annotation: {
              cfiRange,
              text,
              note,
            },
          }),
        });

        if (!response.ok) {
          console.error('Failed to save annotation:', await response.text());
          message.error('Failed to save annotation.');
        } else {
          console.log('Annotation saved successfully:', {
            cfiRange,
            text,
            note,
          });
        }
      } catch (error) {
        console.error('Error saving annotation:', error);
        message.error('An error occurred while saving the annotation.');
      }
    },
    [accessToken, bookId, user.id],
  );

  useEffect(() => {
    const viewerElement = viewerRef.current;

    if (!bookId || !viewerElement) {
      message.error('Book ID or Viewer element is missing.');
      return;
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const queryParams = new URLSearchParams(search);
    const queryLocation = queryParams.get('location');

    try {
      const book = ePub(`${API_BASE_URL}protected/read/${bookId}.epub`, {
        requestHeaders: headers,
      });
      bookRef.current = book;

      const rendition = book.renderTo(viewerElement, {
        width: viewerElement.offsetWidth,
        height: viewerElement.offsetHeight,
        manager: 'continuous',
        flow: 'paginated',
      });

      renditionRef.current = rendition;

      const logSelectionDetails = (cfiRange, contents) => {
        console.log('Rendition.selected triggered');
        console.log('CFI Range:', cfiRange);
        console.log('Contents window:', contents.window);

        const selection = contents.window.getSelection();
        const selectedText = selection?.toString();
        console.log('Selected text:', selectedText);

        if (selectedText?.trim()) {
          const range = selection.getRangeAt(0);
          const rect = range.getBoundingClientRect();

          console.log('Bounding rect before adjustment:', rect);

          // Create context menu
          const menu = document.createElement('div');
          menu.className = 'annotation-menu';

          // Calculate top and left positions
          let top = rect.top + contents.window.scrollY;
          let left = rect.left + rect.width / 2 + contents.window.scrollX;

          // Adjust if menu would overflow off-screen
          const menuWidth = 200; // Approximate width of the menu
          const menuHeight = 50; // Approximate height of the menu
          const padding = 10; // Space between menu and edge of the screen

          if (left + menuWidth > window.innerWidth) {
            left = window.innerWidth - menuWidth - padding;
          }
          if (top + menuHeight > window.innerHeight) {
            top = window.innerHeight - menuHeight - padding;
          }

          menu.style.top = `${top}px`;
          menu.style.left = `${left}px`;

          console.log('Menu positioning after adjustment:', { top, left });

          // Add buttons to the menu
          const highlightButton = document.createElement('button');
          highlightButton.textContent = 'Highlight';
          highlightButton.onclick = () => {
            console.log('Highlight button clicked');
            renditionRef.current.annotations.add(
              'highlight',
              cfiRange,
              {},
              null,
              'highlight',
            );
            saveAnnotation(cfiRange, selectedText);
            if (document.body.contains(menu)) document.body.removeChild(menu);
          };

          const noteButton = document.createElement('button');
          noteButton.textContent = 'Add Note';
          noteButton.onclick = () => {
            console.log('Add Note button clicked');
            const note = prompt('Enter a note for this text:');
            if (note) {
              renditionRef.current.annotations.add(
                'highlight',
                cfiRange,
                {},
                null,
                'highlight',
              );
              saveAnnotation(cfiRange, selectedText, note);
            }
            if (document.body.contains(menu)) document.body.removeChild(menu);
          };

          menu.appendChild(highlightButton);
          menu.appendChild(noteButton);

          // Append the menu to the document
          document.body.appendChild(menu);
          console.log('Menu appended to document:', menu);

          // Remove menu on outside click
          const removeMenu = (e) => {
            if (!menu.contains(e.target)) {
              console.log('Removing annotation menu');
              if (document.body.contains(menu)) document.body.removeChild(menu);
              document.removeEventListener('click', removeMenu);
            }
          };
          document.addEventListener('click', removeMenu);
        }
      };

      rendition.on('selected', logSelectionDetails);

      rendition.themes.register('custom', {
        body: {
          lineHeight: '1.6',
          margin: '0',
          padding: '0',
        },
      });

      rendition.themes.select('custom');
      rendition.themes.fontSize(`${fontSize}px`);

      if (queryLocation) {
        rendition.display(queryLocation).then(() => {
          fetchHighlights();
        });
      } else {
        fetchProgress().then((lastReadPosition) => {
          if (lastReadPosition) {
            rendition.display(lastReadPosition).then(() => {
              fetchHighlights();
            });
          } else {
            rendition.display().then(() => {
              fetchHighlights();
            });
          }
        });
      }

      rendition.on('relocated', (location) => {
        const currentLocation = location.start.cfi;
        saveProgress(currentLocation);
      });

      return () => {
        // Cleanup listeners
        rendition.off('selected', logSelectionDetails);
        saveProgress.flush();
        if (renditionRef.current) {
          renditionRef.current.destroy();
          renditionRef.current = null;
        }
        if (bookRef.current) {
          bookRef.current.destroy();
          bookRef.current = null;
        }
      };
    } catch (error) {
      console.error('Error initializing EPUB:', error);
      message.error('Failed to load the book. Please try again later.');
    }
  }, [
    bookId,
    accessToken,
    search,
    fetchProgress,
    fetchHighlights,
    saveProgress,
    fontSize,
    saveAnnotation,
  ]);

  const handleNext = () => {
    renditionRef.current?.next();
  };

  const handlePrevious = () => {
    renditionRef.current?.prev();
  };

  return (
    <div className="book-reader">
      <div className="book-reader__controls">
        <div className="font-controls">
          <Text>Font Size</Text>
          <Slider
            min={12}
            max={36}
            value={fontSize}
            onChange={setFontSize}
            style={{ width: '200px', margin: '0 10px' }}
          />
        </div>
      </div>
      <div
        className="book-reader__nav book-reader__nav--left"
        onClick={handlePrevious}
        aria-label="Previous Page"
      >
        <LeftOutlined className="book-reader__icon" />
      </div>
      <div id="viewer" ref={viewerRef} className="book-reader__viewer"></div>
      <div
        className="book-reader__nav book-reader__nav--right"
        onClick={handleNext}
        aria-label="Next Page"
      >
        <RightOutlined className="book-reader__icon" />
      </div>
    </div>
  );
};

export default BookReader;
